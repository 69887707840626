<template>
  <div>
    <v-form ref="notesForm">
      <v-card height="370">
        <v-card-title id="notesresizedivheader" class="py-0" :style="$vuetify.theme.dark ? '' : { backgroundColor:systemDetails.themecolor, height:'60px' }">
          <v-text-field style="width:calc(100% - 66px)" @keypress.enter="stopRKey" v-model="notesData.title" dense hide-details full-width solo color="primary"
          :rules="$_requiredValidation">
          </v-text-field>
          <v-spacer></v-spacer>
          <v-btn :disabled="!notesData.title" icon @click="updateNotes" x-small text dark class="mx-3"
          v-if="notesData._id ? accesscontrol && accesscontrol.edit : true">
            <v-icon color="white"> mdi-checkbox-marked-circle-outline </v-icon>
          </v-btn>
          <v-btn icon x-small @click="closeDialog" color="error">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container class="pa-0 pl-1 pt-2">
          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <div class="app-editor">
                <ckeditor :editor="editor" :config="editorConfig" ref="editor" v-model="notesData.content" class="text-edit" placeholder="note"></ckeditor>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>
<script>
/* eslint-disable */
import Editor from '@/ckeditor'
import { mapGetters } from 'vuex'
export default {
  props: ['value', 'fromApp', 'accesscontrol'],
  data () {
    return {
      editor: Editor,
      editorConfig: {
        removePlugins: ['ImageUpload', 'ElementsPath'],
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: [ 'bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
        }
      },
      notesData: {}
    }
  },
  computed: {
    ...mapGetters(['systemDetails','userDetails'])
  },
  watch: {
    value: {
      handler (val) {
        this.notesData = {
          ...val,
          ...(!val._id && { title: `${this.$t('notesOn')} ${this.$formatter.getCurrentDate(`${this.userDetails.dateformat} HH:mm`)}` }),
          ...(!val.content && { content: '' })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    stopRKey (evt) {
      evt.preventDefault()
      if (evt.keyCode === 13) { return false }
    },
    closeDialog () {
      if (this.notesData._id && !this.accesscontrol.edit) {
        this.closeDialogHandler()
        return
      }
      if (this.$refs.notesForm.validate() && this.notesData.content.length) {
        this.notesData = { ...this.notesData, created_at: undefined, modified_at: undefined }
        this.$api.saveUpdateHandler('notes', this.notesData)
          .then(() => {
            this.$eventBus.$emit('loadList')
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.notesData._id ? 'updatedSuccess' : 'savedSuccess' })
          })
          .finally(() => {
            this.closeDialogHandler()
          })
      } else this.closeDialogHandler()
    },
    closeDialogHandler () {
      this.$eventBus.$emit('closeNotePad') 
    },
    closeDialogApp () {
      // this.$eventBus.$emit('appMiniClose')
      this.$eventBus.$emit('appMiniClose', { isTitleValid: this.$refs.notesForm.validate() })
    },
    updateNotes () {
      let model = this.$formatter.cloneVariable({ ...this.notesData, created_at: undefined, modified_at: undefined })
      if (this.$refs.notesForm.validate() && model.content.length) {
        this.$api.saveUpdateHandler('notes', model)
          .then((response) => {
            this.notesData = { ...this.notesData, ...response }
            this.$eventBus.$emit('loadList')
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
          })
          .finally(() => {
            // this.$eventBus.$emit('closeNotePad')
            this.noteUpdating = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    }
  }
}
</script>
<style>
#notesresizediv {
  /* position: absolute; */
  /* z-index: 9; */
  /* background-color: #f1f1f1; */
  text-align: center;
  /* border: 1px solid #d3d3d3; */
}

#notesresizedivheader {
  padding: 10px;
  /* cursor: move; */
  /* z-index: 10; */
  color: #fff;
  height: 55px !important;
}
#notesresizedivheader .v-toolbar__content {
  padding: 0 !important;
}
.app-editor .ck.ck-toolbar {
  background: white !important;
  border: none !important;
}
.app-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: white !important;
}
.app-editor .ck.ck-toolbar[dir=ltr].ck-toolbar_grouping>.ck-toolbar__items, .app-editor [dir=ltr] .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  margin-right: var(--ck-spacing-small);
  border-bottom: 1px solid #0000001a !important;
  border-top: 1px solid #0000001a !important;
  border-right: 1px solid #0000001a !important;
  border-left: 1px solid #0000001a !important;
}
.app-editor .ck.ck-reset.ck-editor {
  overflow: hidden;
}
.app-editor .ck-focused, .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none;
  outline: none !important;
  -moz-outline: none !important;
  -webkit-outline: none !important;
  -ms-outline: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}
.corner-icon {
  position: absolute !important;
  bottom: 15px !important;
  left: 309px !important;
}
</style>
